export default {
  SET_CURRENT_BILLING(state, currentBillingData) {
    state.currentBilling = currentBillingData
    // get info billing
    state.infoBilling = currentBillingData
  },

  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_LOADING_BILLING(state, data) {
    state.loadingBilling = data
  },

  GET_ALL_BILLINGS(state, data) {
    state.allBillings = data
    state.pagination.page = data.current_page
    state.pagination.total = data.total_pages
  }
}
