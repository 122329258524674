import BaseService from './base.service'

class BillingService extends BaseService {
  get entity() {
    return 'admin/'
  }

  getAllBillings(offset, limit, search, chargeover_customer_id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}chargeover_invoices?offset=${offset}&limit=${limit}&order=invoice_id:desc&where=customer_id:EQUALS:${chargeover_customer_id}`
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}

export default new BillingService()
