<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="card-header top-bar">
        <div class="header-left"></div>
        <!-- <div class="header-right">
          <button class="sf-primary" @click="showModalCreate">
            {{ $t('add-new') }}
          </button>
        </div> -->
      </div>
    </div>
    <div class="col-12 sf-table">
      <b-table
        show-empty
        striped
        hover
        :items="allBillings"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loadingBilling"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>
        <template v-slot:head(is_paid)="field">
          <span>{{ $t('status') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:head(invoice_id)="field">
          <span>{{ $t('Invoice No.') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:head(date)="field">
          <span>{{ $t('Invoice Date') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:head(due_date)="field">
          <span>{{ $t('Due Date') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:head(total)="field">
          <span>{{ $t('Total') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:head(balance)="field">
          <span>{{ $t('Balance') }}</span
          ><span class="custome-arrows"></span>
        </template>

        <template v-slot:cell(is_paid)="field">
          <b-badge style="padding: 5px 6px" :variant="field.item.is_paid ? 'success' : 'danger'">
            {{ field.item.is_paid ? 'Paid' : 'Unpaid' }}
          </b-badge>
        </template>
        <template v-slot:cell(date)="field">
          <p class="t-name">{{ moment(field.item.date).format('MMM D, YYYY') }}</p>
        </template>
        <template v-slot:cell(due_date)="field">
          <p class="t-name">{{ moment(field.item.due_date).format('MMM D, YYYY') }}</p>
        </template>
        <template v-slot:cell(total)="field">
          <p class="t-name">{{ field.item.currency_symbol }} {{ field.item.total }}</p>
        </template>
        <template v-slot:cell(balance)="field">
          <p class="t-name">{{ field.item.currency_symbol }} {{ field.item.balance }}</p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button
            :title="$t('tooltip.edit')"
            @click="editBilling(field.item.id)"
            class="button-edit-table"
            style="margin-top: 6px"
          ></button>
          <!-- <button
            :title="$t('tooltip.delete')"
            @click="shownModalremoveBilling(field.item.id)"
            class="button-delete-table"
          ></button> -->
        </template>
      </b-table>

      <div v-if="!isEmpty || this.currentPage > 1" class="py-3">
        <div class="d-flex justify-content-between align-items-center mt-3">
          <!-- Nút Previous -->
          <b-button
            variant="link"
            style="color: #193880"
            :disabled="currentPage === 1"
            @click="prevPage"
          >
            &lt;&lt; Previous
          </b-button>

          <!-- Phân trang -->
          <!-- <b-pagination class="sf-b-pagination" v-model="currentPage" align="center" /> -->

          <!-- Nút Next -->
          <b-button
            variant="link"
            style="color: #193880"
            :disabled="
              currentPage >= totalPages || isEmpty || allBillings.length < pagination.limit
            "
            @click="nextPage"
          >
            Next &gt;&gt;
          </b-button>
        </div>
      </div>
    </div>

    <!-- <SfModal
      :title="$t('modal.title.add-billing')"
      :width="768"
      :show.sync="isShownModal"
      closeOnOverlay
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('first-name') }}</label>
                </div>
                <input
                  v-model="newBilling.billing.first_name"
                  :placeholder="$t('first-name')"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!newBilling.billing.first_name && showErrorAddBilling"
                class="error-text text-right"
              >
                {{ $t('first-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('last-name') }}</label>
                </div>
                <input
                  v-model="newBilling.billing.last_name"
                  :placeholder="$t('last-name')"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!newBilling.billing.last_name && showErrorAddBilling"
                class="error-text text-right"
              >
                {{ $t('last-name-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  v-model="newBilling.billing.email"
                  :placeholder="$t('email')"
                  v-on:keyup="checkEmailExist('', newBilling.billing.email)"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!newBilling.billing.email && showErrorAddBilling"
                class="error-text text-right"
              >
                {{ $t('enter-an-email-address') }}
              </p>
              <p
                v-else-if="
                  newBilling.billing.email &&
                  !checkEmail(newBilling.billing.email) &&
                  showErrorAddBilling
                "
                class="error-text text-right"
              >
                {{ $t('enter-a-valid-email-address') }}
              </p>
              <p v-else-if="!emailCanUsed" class="error-text text-right">
                {{ $t('email-address-already-in-use') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('role') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newBilling.billing.role_id"
                    :options="roleListByBilling"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <p
                v-if="!newBilling.billing.role_id && showErrorAddBilling"
                class="error-text text-right"
              >
                {{ $t('enter-role') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="newBilling.billing.password"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!newBilling.billing.password && showErrorAddBilling"
                class="error-text text-right"
              >
                {{ $t('password-required') }}
              </p>
              <p
                v-else-if="
                  newBilling.billing.password &&
                  !checkPasswordAddBilling(newBilling.billing.password) &&
                  showErrorAddBilling
                "
                class="error-text text-right"
              >
                {{ $t('strong-password-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('confirm-password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="newBilling.billing.password_confirmation"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="
                  newBilling.billing.password_confirmation &&
                  newBilling.billing.password != newBilling.billing.password_confirmation &&
                  showErrorAddBilling
                "
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
              <p
                v-else-if="!newBilling.billing.password_confirmation && showErrorAddBilling"
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModal = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="createBilling">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal> -->
    <!-- update billings -->
    <SfModal
      :title="$t('modal.title.edit-payment')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container mt-3">Coming soon...</div>
      </div>
      <!-- <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('first-name') }}</label>
                </div>
                <input
                  :value="editBillingForm.billing.first_name"
                  @input="changeFirstName"
                  :placeholder="$t('first-name')"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!editBillingForm.billing.first_name && showError"
                class="error-text text-right"
              >
                {{ $t('first-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('last-name') }}</label>
                </div>
                <input
                  :value="editBillingForm.billing.last_name"
                  @input="changeLastName"
                  :placeholder="$t('last-name')"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!editBillingForm.billing.last_name && showError"
                class="error-text text-right"
              >
                {{ $t('last-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  type="email"
                  :value="editBillingForm.billing.email"
                  @input="changeEmail"
                  v-on:keyup="
                    checkEmailExist(editBillingForm.billing.id, editBillingForm.billing.email)
                  "
                  :placeholder="$t('email')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!editBillingForm.billing.email && showError" class="text-right error-text">
                {{ $t('enter-an-email-address') }}
              </p>
              <p
                v-else-if="
                  editBillingForm.billing.email && !checkEmail(editBillingForm.billing.email)
                "
                class="text-right error-text"
              >
                {{ $t('enter-a-valid-email-address') }}
              </p>
              <p v-else-if="!emailCanUsed" class="text-right error-text">
                {{ $t('email-is-currently-used') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('direct-login') }}</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="editBillingForm.billing.direct_login"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleDetailDirectLogin"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="show2FaToogle">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">2FA</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="editBillingForm.billing.is_2fa"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleDetail2fa"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="editBillingForm.billing.billing_type !== 'employee'">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('role') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="editBillingForm.billing.role_id"
                    :options="roleListByBilling"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <p
                v-if="
                  editBillingForm.billing.billing_type !== 'employee' &&
                  !editBillingForm.billing.role_id &&
                  showError
                "
                class="error-text text-right"
              >
                {{ $t('enter-a-role') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="editBillingForm.billing.password"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!checkPassword(editBillingForm.billing.password) && showError"
                class="error-text text-right"
              >
                {{ $t('strong-password-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('confirm-password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="editBillingForm.billing.password_confirmation"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="
                  editBillingForm.billing.password !=
                    editBillingForm.billing.password_confirmation && showError
                "
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="saveEditBilling">
                  {{ $t('update') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <!-- end update billings -->

    <!-- <SfDeleteModal
      :title="$t('modal.title.confirm-delete')"
      closeOnOverlay
      :show.sync="isShownModalDelete"
    >
      <div class="content-delete">
        {{ $t('confirm-delete-billing') }}
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalDelete = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="removeBilling">
                  {{ $t('ok') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfDeleteModal> -->
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import Vue from 'vue'
// import SfDeleteModal from '@/components/SfDeleteModal.vue'
import BillingService from '../../services/billing.service'
export default {
  name: 'billings',
  components: {
    SfModal
  },
  data() {
    return {
      totalPages: 9999999,
      isShownModal: false,
      isShownModalEdit: false,
      isShownModalDelete: false,
      inputError: false,
      checkboxState: false,
      currentBillingId: '',
      fields: [
        {
          key: 'invoice_id',
          colType: 'invoice_id',
          label: 'Invoice No.',
          sortable: true
        },
        {
          key: 'date',
          colType: 'date',
          label: 'Invoice Date',
          sortable: true
        },
        {
          key: 'due_date',
          sortable: true,
          colType: 'due_date',
          label: 'Due Date'
        },
        {
          key: 'customer_id',
          colType: 'customer_id',
          label: 'Customer ID'
        },
        {
          key: 'total',
          sortable: true,
          colType: 'total',
          label: 'Total'
        },
        {
          key: 'balance',
          sortable: true,
          colType: 'balance',
          label: 'Balance'
        },
        {
          key: 'is_paid',
          sortable: true,
          colType: 'is_paid',
          label: 'Status'
        }
      ],
      currentPage: 1,
      newBilling: {
        billing: {
          first_name: '',
          last_name: '',
          password: null,
          password_confirmation: null,
          email: '',
          direct_login: true,
          is_2fa: false,
          role_id: '',
          billing_type: 'customer'
        }
      },
      editBillingForm: {
        billing: {
          id: '',
          first_name: '',
          last_name: '',
          password: null,
          password_confirmation: null,
          email: '',
          direct_login: true,
          is_2fa: false,
          role_id: '',
          billing_type: ''
        }
      },
      showError: false,
      showErrorAddBilling: false,
      dataSearch: '',
      emailCanUsed: true,
      show2FaToogle: false
    }
  },

  methods: {
    ...mapActions('billing', {
      getListRoleByBilling: 'getListRoleByBilling'
    }),
    ...mapActions('user', {
      getCurrentUser: 'getCurrent'
    }),
    ...mapMutations('billing', {
      createBillingFromSocket: 'CREATE_BILLING_FROM_SOCKET',
      deleteBillingFromSocket: 'DELETE_BILLING_FROM_SOCKET',
      updateBillingFromSocket: 'UPDATE_BILLING_FROM_SOCKET'
    }),
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.changePage(this.currentPage)
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.changePage(this.currentPage)
      }
    },
    getAllBillings(page, limit, search, chargeover_customer_id) {
      const offset = (page - 1) * limit
      const payload = {
        offset,
        limit,
        search,
        chargeover_customer_id
      }
      this.$store.dispatch('billing/getAllBillings', payload)
    },
    changePage(page) {
      this.currentPage = page
      this.getAllBillings(
        this.currentPage,
        this.pagination.limit,
        this.dataSearch,
        this.currentUser.chargeover_customer_id
      )
    },
    editBilling(idBilling) {
      this.isShownModalEdit = true
      this.showError = false
      this.editBillingForm.billing.password = null
      this.editBillingForm.billing.password_confirmation = null
      this.editBillingForm.billing.is_2fa = false
      this.currentBillingId = idBilling
      this.$store.dispatch('billing/getInfoBillingByID', idBilling)
      this.emailCanUsed = true
      this.getListRoleByBilling()
    },
    shownModalremoveBilling(id) {
      this.currentBillingId = id
      this.isShownModalDelete = true
    },
    removeBilling() {
      BillingService.deleteBilling(this.currentBillingId)
        .then((_) => {
          this.$toast.success('Successfully delete')
          this.isShownModalDelete = false
          if (this.allBillings.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          // this.getAllBillings(this.currentPage, this.pagination.limit, this.dataSearch)
        })
        .catch((err) => {
          this.isShownModalDelete = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    changeFirstName(e) {
      this.editBillingForm.billing.first_name = e.target.value
    },
    changeLastName(e) {
      this.editBillingForm.billing.last_name = e.target.value
    },
    changeEmail(e) {
      this.editBillingForm.billing.email = e.target.value
    },
    checkPassword(text) {
      const strongPassword = new RegExp('(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      if (!text) {
        return true
      } else if (text && strongPassword.test(text)) {
        return true
      } else {
        return false
      }
    },
    checkPasswordAddBilling(text) {
      // var strongPassword = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$')
      const strongPassword = new RegExp('(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      // if (!text) {
      //   return false
      // }
      if (text && strongPassword.test(text)) {
        return true
      } else {
        return false
      }
    },
    checkEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    saveEditBilling() {
      let conditionBillingType = false
      if (
        this.editBillingForm.billing.billing_type !== 'employee' &&
        this.editBillingForm.billing.role_id
      ) {
        conditionBillingType = true
      } else if (this.editBillingForm.billing.billing_type === 'employee') {
        conditionBillingType = true
      }
      if (
        this.editBillingForm.billing.first_name &&
        this.editBillingForm.billing.last_name &&
        this.editBillingForm.billing.email &&
        this.checkEmail(this.editBillingForm.billing.email) &&
        this.checkPassword(this.editBillingForm.billing.password) &&
        this.editBillingForm.billing.password ===
          this.editBillingForm.billing.password_confirmation &&
        this.emailCanUsed &&
        conditionBillingType
      ) {
        BillingService.editBilling(this.currentBillingId, this.editBillingForm)
          .then((_) => {
            this.isShownModalEdit = false
            this.$toast.success('Successfully updated')
            // this.getAllBillings(this.currentPage, this.pagination.limit, this.dataSearch)
          })
          .catch((err) => {
            this.isShownModalEdit = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showError = true
      }
    },
    createBilling() {
      if (
        this.newBilling.billing.first_name &&
        this.newBilling.billing.last_name &&
        this.newBilling.billing.email &&
        this.checkEmail(this.newBilling.billing.email) &&
        this.checkPasswordAddBilling(this.newBilling.billing.password) &&
        this.newBilling.billing.password === this.newBilling.billing.password_confirmation &&
        this.emailCanUsed &&
        this.newBilling.billing.role_id
      ) {
        delete this.newBilling.billing.direct_login
        delete this.newBilling.billing.is_2fa
        BillingService.addBilling(this.newBilling)
          .then((_) => {
            this.isShownModal = false
            this.$toast.success('Successfully added')
          })
          .catch((err) => {
            this.isShownModal = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showErrorAddBilling = true
      }
    },
    showModalCreate() {
      this.isShownModal = true
      this.showErrorAddBilling = false
      this.newBilling.billing.first_name = ''
      this.newBilling.billing.last_name = ''
      this.newBilling.billing.email = ''
      this.newBilling.billing.password = null
      this.newBilling.billing.password_confirmation = null
      this.newBilling.billing.role_id = ''
      this.emailCanUsed = true
      this.getListRoleByBilling()
    },
    checkEmailExist(id, email) {
      BillingService.checkEmailExist({ billing_id: id, email: email }).then((res) => {
        this.emailCanUsed = res.data.can_use
      })
    },
    onChangeToggleDetail2Fa(value) {
      this.editBillingForm.billing.is_2fa = value.value
    },
    onChangeToggleDetailDirectLogin(value) {
      this.editBillingForm.billing.direct_login = value.value
    },
    onChangeToggleDetail2fa(value) {
      this.editBillingForm.billing.is_2fa = value.value
    }
  },
  async mounted() {
    const user = Vue.$cookies.get('user')
    if (user) {
      this.getAllBillings(
        this.currentPage,
        this.pagination.limit,
        this.dataSearch,
        user.chargeover_customer_id
      )
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe('BillingChannel')
  },
  computed: {
    ...mapState('billing', {
      allBillings: 'allBillings',
      pagination: 'pagination',
      infoBillingByID: 'infoBillingByID',
      listBillingType: 'listBillingType',
      error: 'error',
      loading: 'loading',
      loadingBilling: 'loadingBilling'
    }),
    ...mapGetters('billing', {
      isEmpty: 'isEmpty',
      roleListByBilling: 'roleListByBilling'
    }),
    ...mapGetters('user', {
      currentUser: 'getCurrentUser'
    })
  },
  watch: {
    infoBillingByID: function (value) {
      this.editBillingForm.billing.id = value.id
      this.editBillingForm.billing.first_name = value.first_name
      this.editBillingForm.billing.last_name = value.last_name
      this.editBillingForm.billing.billing_type = value.billing_type
      this.editBillingForm.billing.email = value.email
      this.editBillingForm.billing.is_2fa = value.is_2fa
      this.editBillingForm.billing.direct_login = value.direct_login
      this.editBillingForm.billing.role_id = value.role_id ? value.role_id : ''
      this.show2FaToogle = value.is_2fa
    }
    // currentUser: function (value) {
    //   if (value.chargeover_customer_id) {
    //     this.getAllBillings(
    //       this.currentPage,
    //       this.pagination.limit,
    //       this.dataSearch,
    //       this.currentUser.chargeover_customer_id
    //     )
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.button-delete-table {
  margin-left: 10px !important;
}
.content-delete {
  padding: 35px 45px;
  text-align: center;
  color: #4a4a4a;
  font: 14px 'Open Sans', sans-serif;
}
.btn-create {
  color: #fff !important;
  border-radius: 4px !important;
  height: 40px !important;
  background-color: #55a5f9 !important;
  border-color: #55a5f9 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.btn {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  font: 14px 'Open Sans', sans-serif;
  font-weight: 600;
}

.title-billing {
  justify-content: space-between;
  align-items: center;
  color: #a4aabd;
  height: 70px;
  padding: 19px 21px;
  background-color: white;
  border-color: #f1eff2;
  font-weight: 600;
  margin-left: 15px;
  flex: 0 0 calc(100% - 30px);
}

.exception-row {
  margin: 0;
  margin-top: 20px;
  padding-left: 0;
  margin-left: 15px;

  .header-modal-exception {
    .input-exception {
      height: 40px;
      border-color: #e8e8e8;
      border-radius: 4px;
      padding: 6px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font: 14px 'Opens Sans', sans-serif;
    }

    .check-box {
      margin-top: 9px;

      &:nth-child(2) {
        margin-left: 25px;
      }

      span {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid #126cdb;
        border-radius: 2px;
      }

      p {
        margin-left: 8px;
      }
    }
  }

  .name-exception {
    text-transform: capitalize;
    font: 14px 'Open Sans', sans-serif;
    color: #333333;
    font-weight: bold;
    margin: 10px 0 9px;
  }

  .custom-input {
    height: 34px !important;
    width: 100%;
    border: 0 !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }

  .select-location {
    background: none;
    width: 100%;
    height: 34px;
    transition: box-shadow 0.15s ease;
    border: 0;
    color: #4a4a4a;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    font: 14px Open Sans, sans-serif;
    background-color: white;
    padding: 6px 12px;
  }
}

.two-fa {
  color: #aa0a1e !important;
  &.active {
    color: #4ac67e !important;
  }
}
.sf-b-pagination >>> .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}
</style>
