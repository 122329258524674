import BillingService from '@/services/billing.service'
import Vue from 'vue'

export default {
  getAllBillings({ state, commit }, payload) {
    commit('SET_LOADING_BILLING', true)
    return BillingService.getAllBillings(
      payload.offset,
      payload.limit,
      payload.search,
      payload.chargeover_customer_id
    )
      .then((res) => {
        commit('GET_ALL_BILLINGS', res.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING_BILLING', false))
  }
}
