// profile
import loopPage from '../pages/loop/LoopPage.vue'
import loopDetailPage from '../pages/loop/LoopDetailPage.vue'
// single pages
import homePage from '../pages/Home.vue'
import termPage from '../pages/TermPage.vue'
import accountPage from '../pages/account/AccountPage.vue'
import OrganizationPage from '../pages/account/Organization.vue'
import settingsPage from '../pages/account/Settings.vue'
import profilePage from '../pages/account/Profile.vue'
import billingPage from '../pages/account/Billing.vue'
import usersPage from '../pages/account/Users.vue'
import viewUserPage from '../pages/account/ViewUser.vue'
import subOrgPage from '../pages/account/SubOrganizations.vue'
import libraryPage from '../pages/library/LibraryPage.vue'
import loginPage from '../pages/Login.vue'
import loginSsoPage from '../pages/LoginSso.vue'
import orgPage from '../pages/Organization.vue'
import notFoundPage from '../pages/NotFound.vue'
import campaignPage from '../pages/campaign/CampaignPage.vue'
import editCampaignPage from '../pages/campaign/EditCampaign.vue'
import playerPage from '../pages/player/PlayerPage.vue'
import viewPlayerPage from '../pages/player/ViewPlayer.vue'
import adminPage from '../pages/admin/AdminPage.vue'
import adminOverview from '../pages/admin/dashboard/AdminOverview.vue'
// import adminClient from '../pages/admin/client/Client.vue'
import adminOrganizations from '../pages/admin/clients/organization/AdminOrganizations.vue'
import adminOrganizationView from '../pages/admin/clients/organization/AdminOrganizationView.vue'
import adminPlayer from '../pages/admin/clients/player/AdminPlayer.vue'
import adminPlayerView from '../pages/admin/clients/player/AdminPlayerView.vue'
import adminCustomers from '../pages/admin/clients/customer/AdminCustomers.vue'
import adminCustomerView from '../pages/admin/clients/customer/AdminCustomerView.vue'
import adminPost from '../pages/admin/management/post/adminPost.vue'
import adminTemplate from '../pages/admin/management/template/adminTemplate.vue'
import adminTerm from '../pages/admin/system/terms/adminTerm.vue'
import adminFirmware from '../pages/admin/system/firmware/adminFirmware.vue'
import adminEmployees from '../pages/admin/management/employee/AdminEmployees.vue'
import adminEmployeeView from '../pages/admin/management/employee/AdminEmployeeView.vue'
import adminOrgTypes from '../pages/admin/management/org_type/AdminOrgTypes.vue'
import adminOrgTypeView from '../pages/admin/management/org_type/AdminOrgTypeView.vue'
import adminTax from '../pages/admin/system/tax/adminTax.vue'
import adminSlideTemplates from '../pages/admin/system/slide_template/AdminSlideTemplates.vue'
import adminSlideTemplateEdit from '../pages/admin/system/slide_template/AdminSlideTemplateEdit.vue'
import adminTemplateCategories from '../pages/admin/system/category/AdminTemplateCategories.vue'
import adminTemplateCategoryEdit from '../pages/admin/system/category/AdminTemplateCategoryEdit.vue'
import ForgotPasswordPage from '../pages/ForgotPassword.vue'
import ResetPasswordPage from '../pages/ResetPassword.vue'
import adminRole from '@/pages/admin/system/role/AdminRole.vue'
import uiKit from '../pages/uikit/UiKit.vue'
import typography from '../pages/uikit/Typography.vue'
import button from '../pages/uikit/Button.vue'
import color from '../pages/uikit/Color.vue'
import field from '../pages/uikit/Field.vue'
import notification from '../pages/uikit/Notification.vue'
import cardAndModal from '../pages/uikit/CardAndModal.vue'
import card from '../pages/uikit/Card.vue'
import registerPlayer from '../pages/RegisterPlayer.vue'
import registerPlayerForMobile from '../pages/RegisterPlayerForMobile.vue'
import pagination from '../pages/uikit/Pagination.vue'
import table from '../pages/uikit/Table.vue'
import adminUpdate from '../pages/admin/system/update/adminUpdate.vue'
import adminHardware from '../pages/admin/system/hardware/AdminHardware.vue'
import adminLanguage from '../pages/admin/system/language/AdminLanguage.vue'
import adminLanguageView from '../pages/admin/system/language/AdminLanguageView.vue'

import adminClients from '../pages/admin/clients/AdminClients.vue'
import adminManagement from '../pages/admin/management/AdminManagement.vue'
import adminSystem from '../pages/admin/system/AdminSystem.vue'

import adminSettings from '../pages/admin/clients/settings/AdminSettings.vue'

import { routePropResolver } from './util'
import { DOMAIN_TITLE } from '../.env'

export const routes = [
  {
    path: '/register-player',
    name: 'registerPlayer',
    component: registerPlayer,
    meta: {
      isAuth: true
    }
  },
  {
    path: '/register/player',
    name: 'registerPlayerForMobile',
    component: registerPlayerForMobile,
    meta: {
      isAuth: true
    }
  },
  {
    path: '/',
    name: 'overview',
    redirect: '/overview'
  },
  {
    path: '/overview',
    name: 'index',
    component: homePage,
    meta: {
      isAuth: true,
      showSearchBox: false
    }
  },
  {
    path: '/term-user',
    name: 'termUser',
    component: termPage
  },
  {
    path: '/forgotpassword',
    name: 'forgotPassword',
    component: ForgotPasswordPage
  },
  {
    path: '/users/password/edit',
    name: 'resetPassword',
    component: ResetPasswordPage
  },
  {
    path: '/library',
    name: 'library',
    component: libraryPage,
    meta: {
      isAuth: true,
      currentPage: 'library'
    },
    props: routePropResolver
  },
  {
    path: '/users/:id',
    name: 'user',
    redirect: '/users/:id/edit_organization',
    component: accountPage,
    children: [
      {
        path: 'edit_organization',
        name: 'user/organization',
        component: OrganizationPage,
        meta: {
          currentPage: 'account'
        }
      },
      {
        path: 'edit_setting',
        name: 'user/settings',
        component: settingsPage,
        meta: {
          currentPage: 'account'
        }
      },
      {
        path: 'edit_profile',
        name: 'user/profile',
        component: profilePage,
        meta: {
          currentPage: 'account'
        }
      },
      {
        path: 'billings',
        name: 'user/billing',
        component: billingPage
      },
      {
        path: 'all_users',
        name: 'user/users',
        component: usersPage,
        meta: {
          isAuth: true
        }
      },
      {
        path: 'sub_organizations',
        name: 'user/sub_organizations',
        component: subOrgPage,
        meta: {
          isAuth: true,
          showSearchBox: true
        }
      }
    ],
    meta: {
      isOrgAdmin: true
    },
    props: routePropResolver
  },
  {
    path: '/uiKit',
    name: 'uiKit',
    component: uiKit,
    children: [
      {
        path: 'typography',
        name: 'typography',
        component: typography
      },
      {
        path: 'button',
        name: 'button',
        component: button
      },
      {
        path: 'color',
        name: 'color',
        component: color
      },
      {
        path: 'field',
        name: 'field',
        component: field
      },
      {
        path: 'notification',
        name: 'notification',
        component: notification
      },
      {
        path: 'card-and-modal',
        name: 'cardandmodal',
        component: cardAndModal
      },
      {
        path: 'card',
        name: 'card',
        component: card
      },
      {
        path: 'pagination',
        name: 'pagination',
        component: pagination
      },
      {
        path: 'table',
        name: 'table',
        component: table
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: adminPage,
    redirect: '/admin/dashboard/overview',
    children: [
      {
        path: 'dashboard',
        name: 'adminDashboard',
        redirect: '/admin/organizations',
        component: adminOverview,
        children: [
          {
            path: 'overview',
            name: 'adminOverview',
            component: adminOverview
          }
        ]
      },
      {
        path: 'clients',
        name: 'adminClients',
        component: adminClients,
        children: [
          {
            path: 'organizations',
            name: 'adminOrganizations',
            component: adminOrganizations
          },
          {
            path: 'organizations/:id',
            name: 'adminOrganizationView',
            component: adminOrganizationView
          },
          {
            path: 'players',
            name: 'adminPlayers',
            component: adminPlayer
          },
          {
            path: 'players/:playerID',
            name: 'players admin detail',
            component: adminPlayerView,
            meta: {
              isAuth: true,
              showSearchBox: true
            }
          },
          {
            path: 'users/customers',
            name: 'adminCustomers',
            component: adminCustomers,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'users/customers/:id',
            name: 'adminCustomerView',
            component: adminCustomerView
          },
          {
            path: 'settings',
            name: 'adminSettings',
            component: adminSettings
          }
        ]
      },
      {
        path: 'management',
        name: 'adminManagement',
        meta: {
          isAuth: true
        },
        component: adminManagement,
        children: [
          {
            path: 'users/employees',
            name: 'adminEmployees',
            component: adminEmployees,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'users/employees/:id',
            name: 'adminEmployeeView',
            component: adminEmployeeView
          },
          {
            path: 'org_types',
            name: 'adminOrgTypes',
            component: adminOrgTypes,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'org_types/:id',
            name: 'adminOrgTypeView',
            component: adminOrgTypeView
          },
          {
            path: 'users/posts',
            name: 'adminPost',
            component: adminPost,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'users/templates',
            name: 'adminTemplate',
            component: adminTemplate,
            meta: {
              isAuth: true
            }
          }
        ]
      },

      {
        path: 'system',
        name: 'adminSystem',
        meta: {
          isAuth: true
        },
        component: adminSystem,
        children: [
          {
            path: 'player_apps',
            name: 'adminFirmware',
            component: adminFirmware,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'slide_templates',
            name: 'adminSlideTemplates',
            component: adminSlideTemplates,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'terms',
            name: 'adminTerm',
            component: adminTerm,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'roles',
            name: 'adminRole',
            component: adminRole,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'taxes',
            name: 'adminTax',
            component: adminTax,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'slide_templates/:id/edit',
            name: 'adminSlideTemplateEdit',
            component: adminSlideTemplateEdit
          },
          {
            path: 'update',
            name: 'adminUpdate',
            component: adminUpdate,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'hardwares',
            name: 'adminHardware',
            component: adminHardware,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'languages',
            name: 'adminLanguage',
            component: adminLanguage,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'template_categories',
            name: 'adminTemplateCategories',
            component: adminTemplateCategories
          },
          {
            path: 'languages/:id',
            name: 'adminLanguageView',
            component: adminLanguageView,
            meta: {
              isAuth: true
            }
          },
          {
            path: 'template_categories/:id/edit',
            name: 'adminTemplateCategoryEdit',
            component: adminTemplateCategoryEdit
          }
        ]
      },

      {
        path: 'profile',
        name: 'admin/profile',
        component: profilePage
      },
      {
        path: 'users',
        name: 'admin/users',
        component: usersPage
      }
    ],
    meta: {
      isAdmin: true
    },
    props: routePropResolver
  },
  {
    path: '/campaigns',
    name: 'campaign',
    component: campaignPage,
    meta: {
      isAuth: true,
      currentPage: 'campaigns'
    }
  },
  {
    path: '/users/:userID/view-user',
    name: 'viewUser',
    component: viewUserPage,
    meta: {
      isAuth: true
    }
  },
  {
    path: '/campaigns/:campaignID/edit/',
    name: 'editCampaign',
    component: editCampaignPage,
    meta: {
      isAuth: true,
      currentPage: 'campaigns'
    }
  },
  {
    path: '/loop_assets',
    name: 'loop',
    component: loopPage,
    props: true,
    meta: {
      isAuth: true,
      currentPage: 'loop'
    }
  },
  {
    path: '/loop_assets/:loopId/edit',
    name: 'editLoop',
    component: loopDetailPage,
    props: true,
    meta: {
      isAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: loginPage,
    meta: {
      title: `${DOMAIN_TITLE}  Login`
    }
  },
  {
    path: '/login-sso',
    name: 'loginSso',
    component: loginSsoPage,
    meta: {
      title: `${DOMAIN_TITLE}  Login`
    }
  },
  {
    path: '/signup',
    name: 'organization',
    component: orgPage
  },
  {
    path: '/players',
    name: 'players-page',
    component: playerPage,
    meta: {
      isAuth: true,
      currentPage: 'player'
    }
  },
  {
    path: '/players/:playerID',
    name: 'viewPlayersPage',
    component: viewPlayerPage,
    meta: {
      isAuth: true,
      showSearchBox: true
    }
  },
  {
    path: '*',
    component: notFoundPage,
    name: 'page404'
  }
]
