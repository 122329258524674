<template>
  <transition name="modal">
    <div class="sf-modal overlay component" v-if="show" @click="onClickOverlay" ref="overlay">
      <div
        v-bind:style="{
          width: width + 'px',
          height: height ? height + 'px' : 'auto'
        }"
        class="sf-modal-content"
      >
        <div class="sf-modal-header">
          <h3 class="title">{{ title }}</h3>
          <button class="close" @click="close" v-if="showCloseButton"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div v-if="step === 0">
              <div class="row">
                <div class="col-5">
                  <p class="addmin-modal-title">Organization Details</p>
                  <div class="form-group">
                    <div class="placeholder-modal" v-if="loadingOrg">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <input
                      v-else
                      v-debounce:500ms="onNameChange"
                      type="text"
                      v-model="organization.name"
                      placeholder="Organization Name"
                      class="sf-input-text"
                    />
                    <div
                      class="invalid-error-text"
                      v-if="submitted && !$v.organization.name.required"
                    >
                      Organization name required
                    </div>
                    <div class="invalid-error-text" v-if="submitted && !canUseName">
                      This organization name has already existed
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="placeholder-modal" v-if="loadingOrg">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="sf-select-group">
                      <Select2
                        class="sf-select2"
                        v-model="organization.org_type_id"
                        :options="orgTypeOptions"
                        :settings="{ minimumResultsForSearch: -1 }"
                      />
                      <span class="lb-right caret"></span>
                    </div>
                    <div
                      class="invalid-error-text"
                      v-if="submitted && !$v.organization.org_type_id.required"
                    >
                      Organization Type required
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <div class="placeholder-modal" v-if="loadingOrg">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="sf-select-group">
                      <Select2
                        class="sf-select2"
                        v-model="organization.language"
                        :options="languageConfig"
                        :settings="{ minimumResultsForSearch: -1 }"
                      />
                      <span class="lb-right caret"></span>
                    </div>
                  </div>
                  <p class="addmin-modal-title">Chargeover Customer ID</p>
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="placeholder-modal" v-if="loadingOrg">
                        <content-placeholders>
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </div>
                      <div v-else class="sf-form-group">
                        <input
                          v-model="organization.chargeover_customer_id"
                          class="sf-input-text"
                          type="number"
                          min="0"
                          @keypress="removeNegative"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <p>
                      <input @click="updateActionTitle('parent')" type="radio" id="organizationParent" name="organizationCheck" value="parent" v-model="organizationChecked">
                      <label for="organizationParent">Parent Organization (Default)</label>
                    </p>
                    <p>
                      <input @click="updateActionTitle('child')" type="radio" id="organizationChild" name="organizationCheck" value="child" v-model="organizationChecked">
                      <label for="organizationChild">Child Organization</label>
                    </p>
                  </div> -->
                </div>
                <div class="col-7">
                  <p class="addmin-modal-title">Primary Contact</p>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <div class="placeholder-modal" v-if="loadingOrg">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <input
                          v-else
                          type="text"
                          v-model="organization.fname"
                          placeholder="First Name"
                          class="sf-input-text"
                        />
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.fname.required"
                        >
                          First name required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="placeholder-modal" v-if="loadingOrg">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <input
                          v-else
                          type="text"
                          v-model="organization.lname"
                          placeholder="Last Name"
                          class="sf-input-text"
                        />
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.lname.required"
                        >
                          Last name required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <div class="placeholder-modal" v-if="loadingOrg">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <input
                          v-else
                          v-debounce:500ms="onEmailChange"
                          type="text"
                          v-model="organization.email"
                          placeholder="Email"
                          class="sf-input-text"
                        />
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.email.required"
                        >
                          Email required
                        </div>
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.email.maxLength"
                        >
                          Use less than 100 characters
                        </div>
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.email.email"
                        >
                          Must be a valid e-mail
                        </div>
                        <div class="invalid-error-text" v-if="submitted && !canUseEmail">
                          This email has already existed
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="placeholder-modal" v-if="loadingOrg">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <input
                          v-else
                          type="text"
                          v-model="organization.phone"
                          placeholder="Phone"
                          class="sf-input-text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <div class="placeholder-modal" v-if="loadingOrg">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <gmap-autocomplete
                          v-else
                          class="sf-input-text"
                          @place_changed="setAddressData"
                          placeholder="Organization Address"
                          @input="organization.address = $event.target.value"
                          :value="organization.address"
                        >
                        </gmap-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="step == 1">
              <p class="addmin-modal-title">Organization Billing</p>
              <div class="row">
                <div class="col-6">
                  <p>Which Organization is responsible for billing.</p>
                  <div class="row mt-2">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          :value="true"
                          id="billingOrganizationSelf"
                          v-model="childOrg.self_billing"
                        />
                        <label for="billingOrganizationSelf">Self</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          :value="false"
                          id="billingOrganizationParent"
                          v-model="childOrg.self_billing"
                        />
                        <label for="billingOrganizationParent">Parent</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <p>
                    Description of selected choice goes here, as per Column D in Google Sheets Org
                    Hierarchy + RBAC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="close">Cancel</button>
                  <button type="button" @click="handleCreateNewOrg" class="sf-primary">
                    {{ actionTitle }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import AdminOrganizationService from '@/services/admin-organization.service'
import { language } from '@/config'
import Select2 from 'v-select2-component'
export default {
  name: 'editOrgModal',
  components: {
    Select2
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },

    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    orgID: {
      type: Number,
      default: 0
    },
    width: Number,
    title: String,
    height: Number
  },
  data() {
    return {
      submitted: false,
      canUseEmail: true,
      canUseName: true,
      languageConfig: language(),
      step: 0,
      actionTitle: 'Save',
      organizationChecked: '',
      currentName: '',
      currentEmail: '',
      organization: {
        name: '',
        org_type_id: 0,
        fname: '',
        lname: '',
        address: '',
        email: '',
        phone: '',
        country: '',
        province_state: '',
        postal_zip: '',
        city: '',
        language: 'en',
        chargeover_customer_id: ''
      },
      childOrg: {
        parent_id: '',
        self_billing: ''
      },
      loadingOrg: false,
      addressDataFormat: ''
    }
  },

  validations: {
    organization: {
      name: {
        required
      },
      fname: {
        required
      },
      org_type_id: {
        required
      },
      lname: {
        required
      },
      email: {
        required,
        maxLength: maxLength(100),
        email
      }
    }
  },
  mounted() {
    // this.loadOrgType()
    // this.getItemByID()
  },
  created() {},
  methods: {
    close() {
      this.resetData()
      this.$emit('update:show', false)
      this.$emit('modal:finishUpdate', { onCancel: true })
    },
    removeNegative(event) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault()
      }
    },
    handleCreateNewOrg() {
      this.submitted = true
      if (this.$v.organization.$invalid || !this.canUseName || !this.canUseEmail) {
        return
      }
      if (this.actionTitle === 'Next') {
        this.step += 1
      } else {
        this.saveOrg()
      }
      if (this.step > 0) {
        this.actionTitle = 'save'
      }
    },
    saveOrg() {
      let param = {}
      if (this.organizationChecked === 'parent') {
        param = { organization: this.organization }
      } else {
        const compileParam = { ...this.organization, ...this.childOrg }
        param = { organization: compileParam }
      }
      AdminOrganizationService.updateOrganization(this.orgID, param)
        .then((response) => {
          this.$toast.success('Organization successfully updated.')
          this.$emit('modal:updateSuccess', response.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally((data) => {
          this.$emit('modal:finishUpdate', true)
          this.resetData()
        })
    },
    getItemByID(id) {
      this.loadingOrg = true
      AdminOrganizationService.detailOrganization(id)
        .then((response) => {
          this.loadingOrg = false
          this.organization.name = response.data.name
          this.currentName = response.data.name
          this.organization.org_type_id =
            response.data.org_type && response.data.org_type.id ? response.data.org_type.id : 0
          this.organization.fname = response.data.fname
          this.organization.lname = response.data.lname
          this.organization.email = response.data.email
          this.currentEmail = response.data.email
          this.organization.phone = response.data.phone
          this.organization.address = response.data.address
          this.childOrg.parent_id = response.data.parent_id
          this.childOrg.self_billing = response.data.self_billing
          this.organization.country = response.data.country
          this.organization.province_state = response.data.province_state
          this.organization.city = response.data.city
          this.organization.language = response.data.language
          this.organization.postal_code = response.data.postal_code
          this.organization.chargeover_customer_id = response.data.chargeover_customer_id
        })
        .catch((err) => {
          this.$emit('update:show', false)
          this.$toast.error(err.message)
        })
    },
    onNameChange(val) {
      AdminOrganizationService.checkValidData('check_name', { name: val })
        .then((response) => {
          if (this.currentName === this.organization.name) {
            this.canUseName = true
          } else {
            this.canUseName = response.data.can_use
          }
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    onEmailChange(val) {
      AdminOrganizationService.checkValidData('check_email', { email: val })
        .then((response) => {
          if (this.currentEmail === this.organization.email) {
            this.canUseEmail = true
          } else {
            this.canUseEmail = response.data.can_use
          }
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    },
    updateActionTitle(val) {
      this.actionTitle = val === 'child' ? 'Next' : 'Save'
    },
    resetData() {
      this.step = 0
      this.actionTitle = 'Save'
      this.submitted = false
      this.organizationChecked = ''
      this.organization = {
        name: '',
        org_type_id: 0,
        fname: '',
        lname: '',
        address: '',
        email: '',
        phone: ''
      }
      this.childOrg = {
        parent_id: 0,
        self_billing: ''
      }
    },
    setAddressData(place) {
      this.organization.address = place.formatted_address
      this.formatAddress(place.address_components)
      this.organization.country = this.addressDataFormat.country
      this.organization.province_state = this.addressDataFormat.administrative_area_level_1
      this.organization.city = this.addressDataFormat.locality
        ? this.addressDataFormat.locality
        : ''
      this.organization.address = place.formatted_address
      this.organization.postal_zip = this.addressDataFormat.postal_code
        ? this.addressDataFormat.postal_code
        : ''
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    }
  },
  watch: {
    orgID: function (id) {
      if (id) {
        this.getItemByID(id)
      }
    }
  },
  computed: {
    ...mapState('adminorganization', {
      organizationList: 'parentOrg',
      orgTypeOptions: 'orgTypeOptions'
    })
  },
  beforeDestroy() {
    this.resetData()
  }
}
</script>

<style lang="scss" scoped></style>
