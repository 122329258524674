<template>
  <transition name="modal">
    <div class="sf-modal overlay component" v-if="show" @click="onClickOverlay" ref="overlay">
      <div
        v-bind:style="{
          width: width + 'px',
          height: height ? height + 'px' : 'auto'
        }"
        class="sf-modal-content"
      >
        <div class="sf-modal-header">
          <h3 class="title">{{ title }}</h3>
          <button class="close" @click="close" v-if="showCloseButton"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div v-if="step === 0">
              <div class="row">
                <div class="col-5">
                  <p class="addmin-modal-title">Organization Details</p>
                  <div class="form-group">
                    <input
                      v-debounce:500ms="onNameChange"
                      type="text"
                      v-model="organization.name"
                      placeholder="Organization Name"
                      class="sf-input-text"
                    />
                    <div
                      class="invalid-error-text"
                      v-if="submitted && !$v.organization.name.required"
                    >
                      Organization name required
                    </div>
                    <div class="invalid-error-text" v-if="submitted && !canUseName">
                      This organization name has already existed
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="sf-select-group">
                      <Select2
                        class="sf-select2"
                        v-model="organization.org_type_id"
                        :options="orgTypeOptions"
                        :settings="{ minimumResultsForSearch: -1 }"
                      />
                      <span class="lb-right caret"></span>
                    </div>
                    <div
                      class="invalid-error-text"
                      v-if="submitted && !$v.organization.org_type_id.required"
                    >
                      Organization Type required
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="sf-select-group">
                      <Select2
                        class="sf-select2"
                        v-model="organization.language"
                        :options="languageConfig"
                      />
                      <span class="lb-right caret"></span>
                    </div>
                  </div>
                  <div class="form-group">
                    <p>
                      <input
                        @click="updateActionTitle('parent')"
                        type="radio"
                        class="radio-input"
                        id="organizationParent"
                        name="organizationCheck"
                        value="parent"
                        v-model="organizationChecked"
                      />
                      <label for="organizationParent">Parent Organization (Default)</label>
                    </p>
                    <p>
                      <input
                        @click="updateActionTitle('child')"
                        type="radio"
                        class="radio-input"
                        id="organizationChild"
                        name="organizationCheck"
                        value="child"
                        v-model="organizationChecked"
                      />
                      <label for="organizationChild">Child Organization</label>
                    </p>
                    <p class="addmin-modal-title mt-5">Chargeover Customer ID</p>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="sf-form-group">
                          <input
                            v-model="organization.chargeover_customer_id"
                            class="sf-input-text"
                            type="number"
                            min="0"
                            @keypress="removeNegative"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <p class="addmin-modal-title">Primary Contact</p>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="organization.fname"
                          placeholder="First Name"
                          class="sf-input-text"
                        />
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.fname.required"
                        >
                          First name required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="organization.lname"
                          placeholder="Last Name"
                          class="sf-input-text"
                        />
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.lname.required"
                        >
                          Last name required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          v-debounce:500ms="onEmailChange"
                          type="text"
                          v-model="organization.email"
                          placeholder="Email"
                          class="sf-input-text"
                        />
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.email.required"
                        >
                          Email required
                        </div>
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.email.maxLength"
                        >
                          Use less than 100 characters
                        </div>
                        <div
                          class="invalid-error-text"
                          v-if="submitted && !$v.organization.email.email"
                        >
                          Must be a valid e-mail
                        </div>
                        <div class="invalid-error-text" v-if="submitted && !canUseEmail">
                          This email has already existed
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="organization.phone"
                          placeholder="Phone"
                          class="sf-input-text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <gmap-autocomplete
                          class="sf-input-text"
                          @place_changed="setAddressData"
                          placeholder="Organization Address"
                          @input="organization.address = $event.target.value"
                          :value="organization.address"
                        ></gmap-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="step == 1">
              <p class="addmin-modal-title">Organization Billing</p>
              <div class="row">
                <div class="col-6">
                  <p>Which Organization is responsible for billing.</p>
                  <div class="row mt-2">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          :value="true"
                          name="self_billing"
                          id="billingOrganizationSelf"
                          v-model="childOrg.self_billing"
                        />
                        <label for="billingOrganizationSelf">Self</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          :value="false"
                          name="self_billing"
                          id="billingOrganizationParent"
                          v-model="childOrg.self_billing"
                        />
                        <label for="billingOrganizationParent">Parent</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <p class="mb-2 addmin-modal-title">Parent Organization</p>
                        <div class="sf- select-group">
                          <Select2
                            class="select2"
                            v-model="childOrg.parent_id"
                            :options="organizationList"
                          />
                          <span class="lb-right caret"></span>
                        </div>
                        <div
                          class="invalid-error-text"
                          v-if="submittedChild && !$v.childOrg.parent_id.required"
                        >
                          Please select parent organization
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="step === 2">
              <p class="addmin-modal-title">Content</p>
              <div class="row">
                <div class="col-12">
                  <p>Does this Organization have it’s own Content Library?</p>
                </div>
                <div class="col-6">
                  <div class="row mt-2">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          name="have_library"
                          :value="true"
                          id="orgContentLibraryYes"
                          v-model="childOrg.have_library"
                        />
                        <label for="orgContentLibraryYes">Yes</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          name="have_library"
                          :value="false"
                          id="orgContentLibraryNo"
                          v-model="childOrg.have_library"
                        />
                        <label for="orgContentLibraryNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="step === 3">
              <p class="addmin-modal-title">Loop</p>
              <div class="row">
                <div class="col-12">
                  <p>Does this Organization have it’s own Loop Library?</p>
                </div>
                <div class="col-6">
                  <div class="row mt-2">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          name="have_loop"
                          id="orgLoopYes"
                          @change="selectOrgLoop(true)"
                          :checked="childOrg.have_loop"
                        />
                        <label for="orgLoopYes">Yes</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          name="have_loop"
                          id="orgLoopNo"
                          @change="selectOrgLoop(false)"
                          :checked="!childOrg.have_loop"
                        />
                        <label for="orgLoopNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="step === 4">
              <p class="addmin-modal-title">Campaign</p>
              <div class="row">
                <div class="col-12">
                  <p>Does this Organization have it’s own Campaigns?</p>
                </div>
                <div class="col-6">
                  <div class="row mt-2">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          name="have_campaign"
                          :value="true"
                          id="orgCampaignYes"
                          v-model="childOrg.have_campaign"
                        />
                        <label for="orgCampaignYes">Yes</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="radio"
                          class="radio-input"
                          name="have_campaign"
                          :value="false"
                          id="orgCampaignNo"
                          v-model="childOrg.have_campaign"
                        />
                        <label for="orgCampaignNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="close">Cancel</button>
                  <button
                    :disabled="isLoading"
                    type="button"
                    @click="handleCreateNewOrg"
                    class="sf-primary"
                  >
                    {{ actionTitle }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import AdminOrganizationService from '@/services/admin-organization.service'
import Select2 from 'v-select2-component'
import { language } from '@/config'
export default {
  name: 'UiCreateModal',
  components: {
    Select2
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },

    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    width: Number,
    height: Number
  },
  data() {
    return {
      submitted: false,
      submittedChild: false,
      canUseEmail: true,
      canUseName: true,
      languageConfig: language(),
      step: 0,
      title: 'New Organization',
      actionTitle: 'Save',
      organizationChecked: 'parent',
      organization: {
        name: '',
        language: 'en',
        org_type_id: 0,
        fname: '',
        lname: '',
        address: '',
        email: '',
        phone: '',
        country: '',
        province_state: '',
        postal_zip: '',
        city: '',
        chargeover_customer_id: ''
      },
      addressDataFormat: {},
      childOrg: {
        parent_id: 0,
        self_billing: true,
        have_library: true,
        have_campaign: true,
        have_loop: true
      },
      isLoading: false
    }
  },

  validations: {
    organization: {
      name: {
        required
      },
      fname: {
        required
      },
      org_type_id: {
        required
      },
      lname: {
        required
      },
      email: {
        required,
        maxLength: maxLength(100),
        email
      }
    },
    childOrg: {
      parent_id: {
        required
      }
    }
  },
  mounted() {
    // this.loadOrgType()
    this.$store.dispatch('adminorganization/getParentOrganizations')
  },
  created() {},
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    removeNegative(event) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault()
      }
    },
    loadProvinceState(country) {
      this.$store.dispatch('adminorganization/getListStates', country)
    },
    setAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      const country_id = this.countryOptions.filter(function (elem) {
        if (elem.text === self.addressDataFormat.country) return elem.id
      })
      this.organization.country_id = country_id[0] ? country_id[0].id : 0
      // this.$store.dispatch('adminorganization/updateListStates', { id: self.addressDataFormat.administrative_area_level_1, text: 'Select a Province/State' })
      this.loadProvinceState(this.organization.country_id)

      const province_state = this.provinceStateOptions.filter(function (elem) {
        if (elem.text === self.addressDataFormat.administrative_area_level_1) return elem.id
      })
      this.organization.province_state = province_state[0]
        ? province_state[0].id
        : self.addressDataFormat.administrative_area_level_1
      this.organization.postal_zip = self.addressDataFormat.postal_code
      this.organization.city = self.addressDataFormat.locality

      this.organization.address = place.formatted_address
      this.formatAddress(place.address_components)
      this.organization.country = this.addressDataFormat.country
      this.organization.province_state = this.addressDataFormat.administrative_area_level_1
      this.organization.city = this.addressDataFormat.locality
        ? this.addressDataFormat.locality
        : ''
      this.organization.address = place.formatted_address
      this.organization.postal_zip = this.addressDataFormat.postal_code
        ? this.addressDataFormat.postal_code
        : ''
    },
    handleCreateNewOrg() {
      this.submitted = true
      if (this.$v.organization.$invalid || !this.canUseName || !this.canUseEmail) {
        return
      }
      const radioInputs = document.querySelectorAll('.radio-input')
      radioInputs.forEach((item) => {
        if (item.value === 'true') {
          item.checked = true
        }
      })
      if (this.actionTitle === 'Next') {
        if (this.step === 1) {
          this.submittedChild = true
          if (this.$v.childOrg.$invalid) {
            return
          }
        }
        this.step += 1
      } else {
        this.saveOrg()
      }
      if (this.step > 0) {
        this.title = 'Child Organization Configuration'
      }
      if (this.step > 3) {
        this.actionTitle = 'Save'
      }
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    saveOrg() {
      this.isLoading = true
      let param = {}
      if (this.organizationChecked === 'parent') {
        param = { organization: this.organization }
      } else {
        const compileParam = { ...this.organization, ...this.childOrg }
        param = { organization: compileParam }
      }
      AdminOrganizationService.createOrganization(param)
        .then((response) => {
          this.$toast.success('Successfully created')
          this.$store.dispatch('adminorganization/getParentOrganizations')
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally((data) => {
          this.isLoading = false
          this.$emit('modal:finishSave', true)
        })
    },
    onNameChange(val) {
      AdminOrganizationService.checkValidData('check_name', { name: val })
        .then((response) => {
          this.canUseName = response.data.can_use
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    onEmailChange(val) {
      AdminOrganizationService.checkValidData('check_email', { email: val })
        .then((response) => {
          this.canUseEmail = response.data.can_use
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    },
    updateActionTitle(val) {
      this.actionTitle = val === 'child' ? 'Next' : 'Save'
    },
    resetData() {
      this.step = 0
      this.actionTitle = 'Save'
      this.title = 'New Organization'
      this.submitted = false
      this.organizationChecked = 'parent'
      this.organization = {
        name: '',
        org_type_id: 0,
        fname: '',
        lname: '',
        address: '',
        email: '',
        phone: '',
        country_id: '',
        province_state: '',
        city: '',
        postal_zip: ''
      }
      this.childOrg = {
        parent_id: '',
        self_billing: true,
        have_library: true,
        have_campaign: true,
        have_loop: true
      }
    },
    loadOrgType() {
      this.$store.dispatch('adminorganization/getListOrgtype')
    },
    selectOrgLoop(value) {
      this.childOrg.have_loop = value
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.resetData()
      }
    }
  },
  computed: {
    ...mapState('adminorganization', {
      organizationList: 'parentOrg',
      orgTypeOptions: 'orgTypeOptions',
      countryOptions: 'countryOptions',
      provinceStateOptions: 'provinceStateOptions'
    })
  }
}
</script>

<style lang="scss" scoped></style>
