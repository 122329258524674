<template>
  <div class="container-fluid" ref="scrollableContainer" :class="{ 'disable-page': disablePage }">
    <div class="header-campaign d-flex justify-content-between">
      <!-- Search Unregistered Player -->
      <div :class="{ 'd-none': !(activeButton === 'players') }">
        <SfInputSearch
          v-model="dataSearch"
          :placeholder="$t('search')"
          v-debounce:500ms="searchInputChange"
          :height="'40px'"
          @input="isSearching = true"
          @onSearchInputChange="resetSearchPlayer"
        />
      </div>
      <!-- Search Connected -->
      <div :class="{ 'd-none': !(activeButton === 'connected') }">
        <SfInputSearch
          v-model="dataSearchConnected"
          :placeholder="$t('search')"
          v-debounce:500ms="connectedSearchInputChange"
          :height="'40px'"
          @onSearchInputChange="resetSearchConnected"
        />
      </div>
    </div>

    <div class="sf-table">
      <!-- Table Player -->
      <b-table
        :class="{ 'dl-none': !(activeButton === 'players') }"
        show-empty
        striped
        hover
        :items="listUnregisteredPlayers"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="isLoadingUnregisteredPlayers"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>

        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>
            <router-link
              :to="`/players/${field.item.player_code}`"
              :class="{ 'text-color-grey-40': !field.item.activated }"
              >{{ field.item.name }}</router-link
            >
          </p>
        </template>

        <template v-slot:head(running_campaign)="field">
          <span class="custome-arrows">{{ $t('running-campaign') }}</span>
        </template>
        <template v-slot:cell(running_campaign)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`"
              >{{ field.item.running_campaign }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button @click="connectPlayer(field.item.id)" class="sf-primary" type="button">
            Connect
          </button>
        </template>
      </b-table>
      <div ref="scrollEnd" id="scrollEnd"></div>
    </div>
  </div>
</template>
<script>
import SfInputSearch from '@/components/SfInputSearch.vue'
import { mapState, mapMutations } from 'vuex'
import { timeSince } from '@/helper/helper'
import playerService from '../services/player.service'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'PlayerPageForMobile',
  components: {
    SfInputSearch
  },
  data() {
    return {
      isFirstLoad: true,
      isSearching: false,
      isLoadingMoreItems: false,
      fields: [
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'Action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      fieldsConnected: [
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'Action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      scrollPosition: undefined,
      uniqkey: this.$route.query.uniqkey,
      token: this.$route.query.token,
      dataSearch: '',
      dataSearchConnected: '',
      currentPage: 1,
      currentPageOfConnected: 1,
      limitPage: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      limitPageConnected: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      user: {},
      activeButton: 'players'
    }
  },
  methods: {
    observerScrollEnd() {
      if (this.isFirstLoad) {
        this.isFirstLoad = false // Đánh dấu là đã qua lần tải đầu tiên
        return // Bỏ qua lần đầu tiên
      }
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !this.isLoading && !this.isSearching) {
            this.loadMoreItems()
          }
        },
        {
          rootMargin: '100px',
          threshold: 1.0
        }
      )

      observer.observe(this.$refs.scrollEnd) // Quan sát phần tử để trigger scroll
    },
    onScroll(scrollTop) {
      this.scrollPosition = scrollTop
    },
    timeSince,
    ...mapMutations('player', {
      createPlayerFromSocket: 'CREATE_PLAYER_FROM_SOCKET',
      deletePlayerFromSocket: 'DELETE_PLAYER_FROM_SOCKET',
      updatePlayerFromSocket: 'UPDATE_PLAYER_FROM_SOCKET',
      setLoadingUnregisteredPlayers: 'SET_LOADING_UNREGISTERED_PLAYERS',
      setLoadingConnectedPlayers: 'SET_LOADING_CONNECTED_PLAYERS'
    }),
    loadMoreItems() {
      this.currentPage++
      if (this.currentPage > this.paginationUnregisteredPlayers.total) {
        return
      }
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch, true)
      EventBus.$emit('scrolled-to-bottom', this.scrollPosition) // Emit event để báo đã cuộn đến cuối
    },
    fetchData(activePage, limit, search, append = false) {
      this.$store
        .dispatch('player/getUnregisteredPlayers', { activePage, limit, search, append })
        .then((newData) => {
          if (append) {
            this.$store.commit('player/APPEND_UNREGISTERED_PLAYERS', newData.items)
          }
        })
        .catch(() => {})
        .finally(() => {
          this.observerScrollEnd() // Tạo lại observer
          this.isSearching = false
        })
    },
    fetchDataConnectedPlayers(unqikey, activePage, limit, search) {
      this.$store.dispatch('player/getConnectedPlayers', { unqikey, activePage, limit, search })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changePageConnected(page) {
      this.currentPageOfConnected = page
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value,
        this.dataSearchConnected
      )
    },
    resetSearchPlayer() {
      this.fetchData(this.currentPage, this.limitPage.value)
    },
    resetSearchConnected() {
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value
      )
    },
    changeLimitPage(data) {
      this.currentPage = 1
      this.limitPage = data
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changeLimitPageConnected(data) {
      this.currentPageOfConnected = 1
      this.limitPageConnected = data
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value,
        this.dataSearchConnected
      )
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.isSearching = true
      this.currentPage = 1
      this.fetchData(this.currentPage, this.limitPage.value, value)
    },
    connectedSearchInputChange(value) {
      this.isSearching = true
      this.dataSearchConnected = value
      this.currentPageOfConnected = 1
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value,
        value
      ).finally(() => {
        this.isSearching = false
      })
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    connectPlayer(id) {
      this.setLoadingUnregisteredPlayers(true)
      const data = {
        id: id,
        params: { uniqkey: this.uniqkey, token: this.token }
      }
      playerService
        .connectUnregisteredPlayer(data)
        .then(() => {
          this.$toast.success('Register player successfully')
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.setLoadingUnregisteredPlayers(false)
        })
    },
    reconnectPlayer(id) {
      this.setLoadingConnectedPlayers(true)
      playerService
        .reconnectPlayer(id)
        .then(() => {
          this.$toast.success('Reconnect player successfully')
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.setLoadingConnectedPlayers(false)
        })
    }
  },
  mounted() {
    const container = document.getElementById('pages') // Using id instead of ref
    if (container) {
      container.scrollIntoView({
        behavior: 'smooth',
        block: 'end' // Scroll to the top of the element
      })
    }
    this.observerScrollEnd()
    EventBus.$on('scrollTopPosition', this.onScroll)
    this.getUser()
    this.$cable.subscribe({
      channel: 'PlayersChannel',
      organization: this.user.organization_id
    })
    this.fetchData(this.currentPage, this.limitPage.value)
    this.fetchDataConnectedPlayers(
      this.uniqkey,
      this.currentPageOfConnected,
      this.limitPageConnected.value
    )
  },
  beforeDestroy() {
    this.$cable.unsubscribe('PlayersChannel')
  },
  channels: {
    PlayersChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.createPlayerFromSocket({ data: data.data })
        } else if (data.action === 'delete') {
          this.deletePlayerFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          this.updatePlayerFromSocket({ data: data.data })
        }
      },
      disconnected() {}
    }
  },
  computed: {
    ...mapState('player', {
      error: 'error',
      paginationUnregisteredPlayers: 'paginationUnregisteredPlayers',
      listUnregisteredPlayers: 'listUnregisteredPlayers',
      isLoadingUnregisteredPlayers: 'isLoadingUnregisteredPlayers',
      paginationConnectedPlayers: 'paginationConnectedPlayers',
      listConnectedPlayers: 'listConnectedPlayers',
      isLoadingConnectedPlayers: 'isLoadingConnectedPlayers',
      tokenStatus: 'tokenStatus'
    }),
    disablePage() {
      return !this.tokenStatus
    },
    isEmpty() {
      return this.listUnregisteredPlayers.length < 1
    },
    isEmptyConnectedPlayers() {
      return this.listConnectedPlayers.length < 1
    }
  }
}
</script>
<style lang="scss" scoped>
@import './campaign/campaignPage.scss';
@import './campaign/editCampaign.scss';
@media screen and (max-width: 991px) {
  .pagination-wraper {
    margin-top: 16px;
  }
}
@media screen and (min-width: 990px) and (max-width: 1080px) {
  .sf-sub-menu .top-menu {
    width: 85%;
  }
}
.container-fluid {
  overflow-y: auto; /* Đảm bảo phần tử có thể cuộn */
}
.disable-page {
  pointer-events: none; /* Disable click */
  opacity: 0.5; /* Mờ */
}
</style>
