import axios from 'axios'
// import Http from './http.init'
import { ResponseWrapper } from './util'
import $store from '../store'
import $router from '../router'
import Vue from 'vue'
import { API_URL } from '../.env'
import moment from 'moment-timezone'

/**
 ******************************
 * @API
 ******************************
 */

export function makeLogin({ email, password, remember }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/sign-in`, { email, password })
      .then((response) => {
        Vue.$cookies.set('rememberMe', remember)
        if (!response.data.data.is_2fa) {
          const language = response.data.data.user.language
          Vue.$cookies.set('language', language)
        }
        _setAuthData(response.data)
        return resolve(new ResponseWrapper(response, response.data, response.data.message))
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function requestLoginSso({ email, slug }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/sso-init`, { email, slug })
      .then((response) => {
        return resolve(new ResponseWrapper(response, response.data, response.data.message))
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function makeLoginByCode({ code, tokens, remember }) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: tokens
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/verify-code`, { code }, { headers: headers })
      .then((response) => {
        Vue.$cookies.set('rememberMe', remember)
        const language = response.data.data.user.language
        Vue.$cookies.set('language', language)
        _setAuthData(response.data)
        return resolve(new ResponseWrapper(response, response.data, response.data.message))
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function makeLogout() {
  _resetAuthData()
  $router.push({ name: 'login' })
  // return new Promise((resolve, reject) => {
  //   new Http({ auth: true }).post(`sign-out`)
  //     .then(response => {
  //       // $router.push({ name: 'index' })
  //       return resolve(new ResponseWrapper(response, response.data))
  //     }).catch(error => reject(new ErrorWrapper(error)))
  // })
}
let isRefreshTokens = true
export function refreshTokens() {
  if (isRefreshTokens) {
    isRefreshTokens = false
    const refreshToken = Vue.$cookies.get('refreshToken')
    axios
      .post(
        `${API_URL}/refresh-token`,
        {},
        {
          headers: { 'X-Refresh-Token': refreshToken }
        }
      )
      .then((response) => {
        _setAuthData(response.data)
        location.reload()
        // return resolve(new ResponseWrapper(response, response.data))
      })
      .catch((error) => {
        if (error.response.data.badRefreshToken) {
          _resetAuthData()
          $router.push({ name: 'index' })
        }
        if (error.response.data.refreshTokenExpiredError) {
          _resetAuthData()
          $router.push({ name: 'index' })
        }
      })
  }
}

export function makeLoginSSO(response) {
  return new Promise((resolve, reject) => {
    _setAuthData(response)
    return resolve()
  })
}
/**
 ******************************
 * @methods
 ******************************
 */

export function isAccessTokenExpired() {
  const accessTokenExpDate = Vue.$cookies.get('tokenExpired')
  const expDate = Date.parse(accessTokenExpDate)
  const nowTime = Math.floor(new Date().getTime())
  return expDate <= nowTime
}

export function getRefreshToken() {
  return Vue.$cookies.get('refreshToken')
}

export function getAccessToken() {
  return Vue.$cookies.get('accessToken')
}

function _resetAuthData() {
  // reset userData in store
  $store.commit('user/SET_CURRENT_USER', {})
  $store.commit('auth/SET_ATOKEN_EXP_DATE', null)
  // reset tokens in cookies
  Vue.$cookies.remove('accessToken')
  Vue.$cookies.remove('refreshToken')
  Vue.$cookies.remove('tokenExpired')
  Vue.$cookies.remove('rememberMe')
  Vue.$cookies.remove('user')
  Vue.$cookies.remove('token')
  Vue.$cookies.remove('uniqkey')
}

function _setAuthData(response) {
  const user = JSON.stringify(response.data.user)
  const remember = Vue.$cookies.get('rememberMe') === 'true'
  if (remember) {
    if (response.data.auth_token) {
      Vue.$cookies.set(
        'accessToken',
        response.data.auth_token,
        formatTime(response.data.access_expires_at)
      )
    }
    if (response.data.refresh_token) {
      Vue.$cookies.set('refreshToken', response.data.refresh_token, '1y')
    }
    Vue.$cookies.set(
      'tokenExpired',
      response.data.access_expires_at,
      formatTime(response.data.access_expires_at)
    )
    if (user) {
      Vue.$cookies.set('user', user, formatTime(response.data.access_expires_at))
    }
  } else {
    if (response.data.auth_token) {
      Vue.$cookies.set('accessToken', response.data.auth_token, 0)
    }
    if (response.data.refresh_token) {
      Vue.$cookies.set('refreshToken', response.data.refresh_token, 0)
    }

    if (response.data.access_expires_at) {
      Vue.$cookies.set('tokenExpired', response.data.access_expires_at, 0)
    }
    if (user) {
      Vue.$cookies.set('user', user, 0)
    }
  }
}

function formatTime(time) {
  return moment(time).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
}
