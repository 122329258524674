import auth from './auth'
import user from './user'
import billing from './billing'
import dom from './dom'
import toast from './toast'
import library from './library'
import loop from './loop'
import loopdetail from './loopdetail'
import campaign from './campaign'
import player from './player'
import overview from './overview'
import instagrameditor from './instagrameditor'
import smartasseteditor from './smartAssetEditor'
import adminorganization from './admin/organization'
import adminoverview from './admin/overview'
import adminplayer from './admin/player'
import adminuser from './admin/user'
import adminsettings from './admin/settings'
import adminhardware from './admin/hardware'
import adminprogram from './admin/program'
import adminpost from './admin/post'
import adminterm from './admin/terms'
import adminfirmware from './admin/firmware'
import adminorgtype from './admin/org_type'
import admintax from './admin/tax'
import adminlanguage from './admin/language'
import adminslidetemplate from './admin/slide_template'
import admintemplatecategory from './admin/template_category'
import suborg from './suborg'
import admintemplate from './admin/template'

export default {
  auth,
  user,
  billing,
  dom,
  toast,
  library,
  loop,
  loopdetail,
  campaign,
  overview,
  smartasseteditor,
  instagrameditor,
  player,
  adminorganization,
  adminoverview,
  adminplayer,
  adminuser,
  adminhardware,
  adminprogram,
  adminpost,
  adminterm,
  adminfirmware,
  adminorgtype,
  admintax,
  adminslidetemplate,
  suborg,
  adminlanguage,
  admintemplatecategory,
  admintemplate,
  adminsettings
}
