export default {
  loading: false,
  loadingBilling: false,
  error: false,
  currentBilling: {},

  infoBilling: '',
  allBillings: [],
  pagination: {
    limit: 10,
    page: 0,
    total: 0
  },
  infoBillingByID: '',
  listBillingType: '',
  listRole: '',
  customRole: ''
}
