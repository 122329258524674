<template>
  <div id="app">
    <AppHeader v-if="showPartials"></AppHeader>
    <AppLeftSidebar v-if="showPartials" @run-tour="runTour1stLogin(false)"></AppLeftSidebar>
    <div
      id="pages"
      class="pages"
      v-bind:class="{ 'login-page': !showPartials, 'disable-scroll': !disableScroll }"
      ref="scrollableContainer"
      @scroll="onScroll"
    >
      <router-view></router-view>
    </div>
    <v-tour
      name="tutorialTour"
      :steps="steps"
      :options="{ highlight: true }"
      :callbacks="checkRouteCallbacks"
    ></v-tour>
  </div>
</template>

<script>
// import { refreshPageMixin } from '@/mixins/refresh-page.mixin'
import AppHeader from './Header.vue'
import AppLeftSidebar from './LeftSidebar.vue'
import * as onboarding from '../constant/onboardingTutorial.js'
import UsersService from '@/services/users.service'
import moment from 'moment-timezone'
import { EventBus } from '@/event-bus.js'
export default {
  name: 'AppLayout',
  components: {
    AppHeader,
    AppLeftSidebar
  },
  // mixins: [refreshPageMixin],
  data() {
    return {
      steps: [
        {
          target: '#v-step-1'
        }
      ],
      checkRouteCallbacks: {
        onPreviousStep: this.previousStepCallback,
        onFinish: this.finishStepCallback,
        onSkip: this.skipTourCallback
      }
    }
  },
  computed: {
    showPartials() {
      const currentPage = this.$route.name
      return (
        currentPage !== 'login' &&
        currentPage !== 'loginSso' &&
        currentPage !== 'page404' &&
        currentPage !== 'termUser' &&
        currentPage !== 'forgotPassword' &&
        currentPage !== 'handleCallback' &&
        currentPage !== 'loginSso' &&
        currentPage !== 'uiKit' &&
        currentPage !== 'button' &&
        currentPage !== 'cardandmodal' &&
        currentPage !== 'card' &&
        currentPage !== 'color' &&
        currentPage !== 'typography' &&
        currentPage !== 'notification' &&
        currentPage !== 'field' &&
        currentPage !== 'table' &&
        currentPage !== 'resetPassword' &&
        currentPage !== 'pagination' &&
        currentPage !== 'organization'
      )
    },
    disableScroll() {
      const currentPage = this.$route.name
      return currentPage !== 'library' && currentPage !== 'loop'
    }
  },
  watch: {
    $route: function (to, from) {
      this.$bus.$emit('resetInputSearch', '')
    },
    $currentUser: {
      handler() {
        this.runTour1stLogin(true)
      }
    }
  },
  methods: {
    onScroll() {
      const scrollTop = this.$refs.scrollableContainer.scrollTop
      EventBus.$emit('scrollTopPosition', scrollTop)
    },
    triggerScroll(scrollTopPosition) {
      const container = this.$refs.scrollableContainer
      if (container) {
        container.scrollTo({
          top: scrollTopPosition,
          behavior: 'smooth'
        })
      }
    },
    previousStepCallback(currentStep) {
      if (currentStep === 3) {
        this.$router.push({ name: 'overview' })
      }
    },
    finishStepCallback() {
      UsersService.updateGuided()
        .then(() => {
          const user = this.$cookies.get('user')
          user.is_guided = true
          const tokenExpired = this.$cookies.get('tokenExpired')
          const remember = this.$cookies.get('rememberMe') === 'true'
          if (tokenExpired && remember) {
            this.$cookies.set(
              'user',
              user,
              moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
            )
          } else {
            this.$cookies.set('user', user, 0)
          }
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    async skipTourCallback() {
      try {
        await UsersService.updateGuided()
        const user = this.$cookies.get('user')
        user.is_guided = true
        this.$cookies.set('user', user, 0)
      } catch (error) {
        this.$toast.error(error.message ? error.message : 'Something went wrong. Please try again.')
      }
    },
    runTour1stLogin(isFirstLogin) {
      const user = this.$cookies.get('user')
      if (user.is_guided !== undefined && (!user.is_guided || !isFirstLogin)) {
        if (user.menu) {
          this.steps = [
            onboarding.dashboardStep1,
            onboarding.dashboardStep2,
            onboarding.dashboardStep3
          ]
          if (user.menu.library) {
            this.steps.push(onboarding.library)
          }
          if (user.menu.loop) {
            this.steps.push(onboarding.loops)
            this.steps.push(onboarding.loopEditor)
          }
          if (user.menu.campaigns) {
            this.steps.push(onboarding.campaigns)
          }
          if (user.menu.player) {
            this.steps.push(onboarding.player)
          }
        } else {
          this.steps = [
            onboarding.dashboardStep1,
            onboarding.dashboardStep2,
            onboarding.dashboardStep3,
            onboarding.library,
            onboarding.loops,
            onboarding.loopEditor,
            onboarding.campaigns,
            onboarding.player
          ]
        }
        this.$tours.tutorialTour.start()
        if (this.$route.name !== 'overview') {
          this.$router.push({ name: 'overview' }).catch((error) => {
            if (
              error.name !== 'NavigationDuplicated' &&
              !error.message.includes('Avoided redundant navigation to current location')
            ) {
            }
          })
        }
      }
    }
  },
  mounted() {
    EventBus.$on('scrolled-to-bottom', this.triggerScroll)
  }
}
</script>
<style lang="scss" scoped>
.refresh-container {
  width: 15%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #ffffff;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}
.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
}
#app ::v-deep .v-step {
  max-width: 610px;
  background: #0055ff;
}
#app ::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.1);
}
</style>
