import Vue from 'vue'
export default {
  GET_LIST_PLAYER(state, data) {
    state.listPlayer = data.items
    state.pagination.total = data.total_pages
    state.pagination.page = data.current_page
    state.error = false
  },
  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_EMPTY(state, data) {
    state.isEmpty = data
  },
  GET_INFO_PLAYER_BY_ID(state, data) {
    state.infoPlayerID = data
    if (!state.infoPlayerID.running_campaign) {
      state.infoPlayerID.running_campaign = { id: 0 }
    }
    state.infoPlayerID.tags = state.infoPlayerID.tags.map((tag) => {
      return { text: tag.name, ...tag }
    })
  },
  // GET_PAGINATION (state, data) {
  //   state.pagination.total = data.total_pages
  //   state.pagination.page = data.current_page
  // },
  GET_ALL_CAMPAIGNS(state, data) {
    state.allCampaigns = data
  },
  SET_TOKEN_STATUS(state, status) {
    state.tokenStatus = status
  },
  UPDATE_LOCATION(state, data) {
    if (data.info) {
      state.infoPlayerID.lat = data.place.geometry.location.lat()
      state.infoPlayerID.lng = data.place.geometry.location.lng()
      state.infoPlayerID.postal_zip = data.info.postal_code
      state.infoPlayerID.city = data.info.locality
      state.infoPlayerID.province_state = data.province_state
      state.infoPlayerID.country = data.country
    }
    state.infoPlayerID.location = data.name
  },

  GET_LIST_TIMEZONE(state, data) {
    state.listTimezone = data
  },

  UPDATE_PLAYER_NAME(state, data) {
    state.infoPlayerID.name = data
  },

  UPDATE_DESCRIPTION_PLAYER(state, data) {
    state.infoPlayerID.description = data
  },

  CHOOSE_TIMEZONE(state, data) {
    state.infoPlayerID.time_zone_name = data
    state.infoPlayerID.time_zone_value = data
  },

  CHOOSE_CAMPAIGN(state, data) {
    state.infoPlayerID.campaign_id = data.id
  },

  CHANGE_ROTATE(state, data) {
    state.infoPlayerID.rotate = data
  },

  CHANGE_TOGGLE_CACHE(state, data) {
    state.infoPlayerID.is_browser_cache = data
  },

  CHANGE_TOGGLE_WIFI_TYPE(state, data) {
    state.infoPlayerID.is_wifi_type = data
  },

  CHANGE_TOGGLE_KIOSK(state, data) {
    state.infoPlayerID.is_kiosk_mode = data
  },

  GET_ALL_STATES(state, data) {
    state.allStates = data
  },

  GET_ALL_FILES(state, data) {
    state.files = data.items
    state.pagination.total = data.total_pages
    state.pagination.page = data.current_page
  },

  GET_ALL_PLAYERS_WITH_ORIENTATION(state, data) {
    state.allPlayersWithOrientaion = data
  },

  DELETE_PLAYER_WHEN_ADD(state, data) {
    state.allPlayersWithOrientaion = state.allPlayersWithOrientaion.filter(
      (player) => parseInt(player.id) !== parseInt(data)
    )
  },
  DELETE_PLAYER(state, data) {
    state.listPlayer = state.listPlayer.filter((player) => parseInt(player.id) !== parseInt(data))
  },

  REMOVE_AND_RETURN_DATA(state, data) {
    state.allPlayersWithOrientaion.push(data)
    if (data.hasOwnProperty('last_connection')) {
      data.time = new Date(data.last_connection).getTime()
    }
  },

  GET_GROUPS_PLAYER(state, data) {
    state.listPlayerGroup = data.items
    state.paginationGroup.total = data.total_pages
    state.paginationGroup.page = data.current_page
  },

  GET_NEW_LIST_PLAYER_WHEN_GET_DETAIL_GROUP(state, data) {
    data.map((player) => {
      state.allPlayersWithOrientaion = state.allPlayersWithOrientaion.filter((x) => {
        return parseInt(x.id) !== parseInt(player.id)
      })
    })
  },

  SET_NEW_CAMPAIGN_PLAYER_PAGE(state, data) {
    if (data.campaign && data.campaign.hasOwnProperty('displayname')) {
      data.campaign.text = data.campaign.displayname
    }
    state.listPlayer.map((player) => {
      if (data.listPlayerChosen.includes(player.id.toString())) {
        player.running_campaign = data.campaign && data.campaign.id ? data.campaign.text : ''
        player.campaign_code =
          data.campaign && data.campaign.campaign_code ? data.campaign.campaign_code : ''
        player.orientation = data.campaign && data.campaign.id ? data.orientation : ''
        player.group_player = { name: data.nameGroup }
      }
    })
  },

  CHECK_PLAYER_DELETE_GROUP(state, data) {
    state.listPlayer.map((player) => {
      if (player.group_player && player.group_player.name === data) {
        player.group_player = ''
      }
    })
  },

  DELETE_PLAYER_IN_PLAYER_CHOSEN(state, data) {
    if (data.length) {
      const listNameChosed = []
      data.map((player) => {
        if (player.hasOwnProperty('group_player')) {
          listNameChosed.push(player.name)
        }
      })
      state.listPlayer.map((player) => {
        if (listNameChosed.includes(player.name)) {
          player.group_player = ''
        }
      })
    }
  },

  SET_LOADING_FLIES(state, data) {
    state.loadingFiles = data
  },

  SET_DATA_PLAYER(state, data) {
    state.infoPlayerID.name = data.name
    state.infoPlayerID.description = data.description
    state.infoPlayerID.campaign_code = data.campaign_code
    state.infoPlayerID.rotate = data.rotate
    state.infoPlayerID.location = data.location
    state.infoPlayerID.city = data.city
    state.infoPlayerID.postal_zip = data.postal_zip
    state.infoPlayerID.province_state = data.province_state
    state.infoPlayerID.lat = data.lat
    state.infoPlayerID.lng = data.lng
    state.infoPlayerID.time_zone_name = data.time_zone_name
    state.infoPlayerID.time_zone_value = data.time_zone_value
    state.infoPlayerID.pin = data.pin
    if (!state.infoPlayerID.running_campaign) {
      state.infoPlayerID.running_campaign = { id: 0 }
    }
    state.infoPlayerID.running_campaign.displayname = data.running_campaign
    state.infoPlayerID.running_campaign.id = data.campaign_id
    state.infoPlayerID.orientation = data.orientation
    state.infoPlayerID.country = data.country
  },

  CREATE_PLAYER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.listPlayer)
    items.unshift(data.data)
    state.listPlayer = items
  },

  DELETE_PLAYER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.listPlayer)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    items.splice(foundIndexItems, 1)
    state.listPlayer = items
  },

  UPDATE_PLAYER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.listPlayer)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    if (foundIndexItems >= 0) {
      items[foundIndexItems] = data.data
      let running_campaign = ''
      if (data.data.running_campaign && data.data.running_campaign.displayname) {
        running_campaign = data.data.running_campaign.displayname
      } else if (data.data.running_campaign) {
        running_campaign = data.data.running_campaign
      }
      items[foundIndexItems].running_campaign = running_campaign
    }
    state.listPlayer = items
  },

  CREATE_GROUP_PLAYER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.listPlayerGroup)
    items.unshift(data.data)
    state.listPlayerGroup = items
  },

  DELETE_GROUP_PLAYER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.listPlayerGroup)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    items.splice(foundIndexItems, 1)
    state.listPlayerGroup = items
  },

  UPDATE_GROUP_PLAYER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.listPlayerGroup)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    if (foundIndexItems >= 0) {
      items[foundIndexItems] = data.data
    }
    state.listPlayerGroup = items
  },

  SET_LIST_PLAYER_CHILDRED(state, data) {
    state.dataPlayerChildren = data
  },

  SET_LOADING_DATA_PLAYER_CHILDREN(state, data) {
    state.isLoadingDataPlayerChildren = data
  },

  SET_UNREGISTERED_PLAYERS(state, data) {
    state.oldListUnregisteredPlayers = state.listUnregisteredPlayers
    state.listUnregisteredPlayers = data.items
    state.paginationUnregisteredPlayers.total = data.total_pages
    state.paginationUnregisteredPlayers.page = data.current_page
    state.error = false
  },
  APPEND_UNREGISTERED_PLAYERS(state, data) {
    state.listUnregisteredPlayers = [...state.oldListUnregisteredPlayers, ...data]
  },

  SET_LOADING_UNREGISTERED_PLAYERS(state, data) {
    state.isLoadingUnregisteredPlayers = data
  },

  SET_CONNECTED_PLAYERS(state, data) {
    state.listConnectedPlayers = data.items
    state.paginationConnectedPlayers.total = data.total_pages
    state.paginationConnectedPlayers.page = data.current_page
    state.error = false
  },

  SET_LOADING_CONNECTED_PLAYERS(state, data) {
    state.isLoadingConnectedPlayers = data
  },

  SET_EDITABLE_PLAYER_IDS(state, data) {
    state.editPlayerIds.push(data)
    if (
      !state.editPlayerIds.some(
        (item) => item.resourceable_id === data.resourceable_id && item.user_id === data.user_id
      )
    ) {
      state.editPlayerIds.push(data)
    }
  },
  LOAD_EDITABLE_PLAYER(state, data) {
    state.editPlayerIds = data
  },
  RESET_EDITABLE_PLAYER_IDS(state, data) {
    state.editPlayerIds = state.editPlayerIds.filter((item) => {
      return !(item.player_id === data.player_id)
    })
  },
  SET_PLAYER_TAGS(state, data) {
    state.infoPlayerID.tags = data
  },
  SET_SEARCH_PLAYER_INPUT(state, data) {
    state.searchPlayerInput = data
  }
}
