import state from './billingState'
import mutations from './billingMutations'
import getters from './billingGetters'
import actions from './billingActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
